import React from "react";
import { Link } from "gatsby";
import blogImage from "../images/img15.jpg";
const Donate = () => {
  return (
    <section className="donate-area2">
      <div className="container">
        <div className="row donate-content-wrap">
          <div className="col-lg-8">
            <div className="donate-item">
              <h3 className="donate__title">
                Click the orange button below to donate online
              </h3>
              <div className="row">
                <div className="col-lg-6">
                  <Link
                    to="https://rzp.io/l/pdjjks"
                    target="blank"
                     className="theme-btn"
                  >
                    Donate
                  </Link>
                </div>
                <div className="col-lg-6">
                  <Link
                    to="https://rzp.io/l/ekbvVS1S"
                    target="blank"
                    className="theme-btn"
                  >
                    Donate with Tax Benefit
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4">
            <div className="sidebar-shared">
              <div className="side-widget blog-content">
                <div className="blog-item">
                  <div className="blog-img">
                    <img src={blogImage} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Donate;
